.first-row {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.first-row::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

.home-wrapper {
	/* background-color: aqua; */
	/* height: 100vh; */
	/* min-width: 100%; */
}
.first-row-wrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	height: max-content;
	align-items: center;
	justify-content: center;
	margin: 2rem 0rem;
	/* background-color: aqua; */
}
.first-row-wrapper > h1 {
	font-size: max(2.5rem, 3.5vw);
}
.first-row {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.837);
	height: max-content;
	max-width: 100%;
	overflow-x: auto;
	scroll-behavior: smooth;
}

.top-img-wrapper {
	/* background-color: aqua; */
	/* width: 40%; */
	/* height: 30%; */
	/* aspect-ratio: 1; */
	margin: 1rem;
	background-color: aliceblue;
	/* transition: 300ms ease-out; */
}
.top-img-wrapper > p {
	min-width: 100%;
	/* background-color: blanchedalmond; */
	font-weight: bold;
	padding: 0.5rem 0rem;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 160px;
	height: 1.2em;
	white-space: nowrap;
}
.top-img-wrapper > img {
	height: max(10rem, 15vw);
	/* width: 10rem; */
}
.top-img-wrapper:hover {
	transform: translateY(-10px);
	transform: scale(1.1);
	transition: 300ms ease-in-out;
}
.first-row > button {
	/* top: 10px; */
	height: 80%;
	width: 50px;
	position: absolute;
	background-color: rgb(0, 0, 0);

	border: none;
	opacity: 0.2;
	transition: 300ms ease-out;
	margin: 1rem 0rem;
}
.first-row > button:hover {
	cursor: pointer;
	opacity: 0.6;
	transition: 300ms ease-in;
}
.btn-left {
	left: 0;
	border-radius: 0rem 1rem 1rem 0rem;
	z-index: 10;
}
.btn-left > img {
	/* color: aliceblue; */
	/* opacity: 1; */
	/* color: black; */
	padding: 0;
	margin: 0;
	width: 1.5rem;
	z-index: 100;
	transition: 200ms ease-out;
}
.btn-right > img {
	color: aliceblue;
	/* opacity: 1; */
	/* color: black; */
	padding: 0;
	margin: 0;
	width: 1.5rem;
	z-index: 100;
	transition: 200ms ease-out;
}
.btn-right {
	right: 0;
	z-index: 10;
	border-radius: 1rem 0rem 0rem 1rem;
}
.first-row > button > img:hover {
	transform: scale(1.7);
	transition: 200ms ease-in;
}

@media only screen and (max-width: 600px) {
	.first-row > button {
		display: none;
	}
}
