.slider-row {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.slider-row::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}
.double-row-wrapper {
	/* text-align: center; */
}
.second-row-title {
	font-size: max(2.5rem, 3.5vw);
}
.second-row-title {
	/* background-color: aqua; */
	text-align: center;
}
.double-slider-area {
	position: relative;
	background-color: black;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* max-width: 90%; */
	/* padding: 0rem 2rem; */
}
.double-slider-area > button {
	top: 0;
	height: 100%;
	width: 50px;
	position: absolute;
	background-color: rgb(0, 0, 0);
	border: none;
	opacity: 0.3;
	transition: 300ms ease-out;
	z-index: 10;
}
.slider-row {
	/* background-color: aqua; */
	display: flex;
	align-items: center;
	justify-content: center;
	height: max-content;
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	scroll-behavior: smooth;
}
.second-img-wrapper {
	margin: 1rem;
	background-color: aliceblue;
	/* transition: 300ms ease-out; */
	height: 15rem;
}
.second-img-wrapper:hover {
	transform: translateY(-10px);
	transform: scale(1.1);
	transition: 300ms ease-in-out;
}
.second-img-wrapper > img {
	height: 80%;
}
.second-img-wrapper > p {
	padding: 0.5rem;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 160px;
	height: 1.2em;
	white-space: nowrap;
}

.double-slider-area > button > img {
	padding: 0;
	margin: 0;
	width: 1.5rem;
	z-index: 100;
	transition: 200ms ease-out;
}
.btn-left2 {
	left: 0;
}
.btn-right2 {
	right: 0;
}
.double-slider-area > button:hover {
	cursor: pointer;
	opacity: 0.6;
	transition: 300ms ease-in;
}
.double-slider-area > button > img:hover {
	transform: scale(1.7);
	transition: 200ms ease-in;
}

@media only screen and (max-width: 600px) {
	.double-slider-area > button {
		display: none;
	}
}
